'use strict'

angular
  .module 'mundoAdmin.contacts'
  # .config ($stateProvider) ->
  #   $stateProvider
  #     .state 'contacts',
  #       url: '/contacts'
  #       parent: 'admin'
  #       views:
  #         '@admin':
  #           templateUrl: 'mundo-admin/contacts/views/contacts.tpl.html'
  #           controller: 'TabsCtrl'
  #           controllerAs: 'contacts'
  #       deepStateRedirect: { default: { state: 'contacts.overview' } },

  #     .state 'contacts.overview',
  #       url: '/overview'
  #       data:
  #         'selectedTab': 0
  #       views:
  #         'contacts@contacts':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (ContactManager) ->
  #               ContactManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.contacts.contacts'
  #             pageDescription: ->
  #               'app.admin.descriptions.contacts.contacts'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.contacts.deleteContact'
  #             deleteBoxBody: ->
  #               'app.admin.actions.contacts.deleteContact'
  #             AddBoxTitle: ->
  #               'app.admin.actions.contacts.addContact'
  #             editBoxTitle: ->
  #               'app.admin.actions.contacts.editContact'
  #             loadFilterParams: ->
  #               {}
  #     .state 'contacts.types',
  #       url: '/types'
  #       data:
  #         'selectedTab': 1
  #       views:
  #         'types@contacts':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (ContactTypeManager) ->
  #               ContactTypeManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.contacts.contactTypes'
  #             pageDescription: ->
  #               'app.admin.descriptions.contacts.contactTypes'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.contacts.deleteContactType'
  #             deleteBoxBody: ->
  #               'app.admin.actions.contacts.deleteContactType'
  #             AddBoxTitle: ->
  #               'app.admin.actions.contacts.addContactType'
  #             editBoxTitle: ->
  #               'app.admin.actions.contacts.editContactType'
  #             loadFilterParams: ->
  #               {}
